.generalContainer {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.globalCoverage {
    max-width: 100%;
    padding-right: 0;
}

.signUpLoginContainer {
    margin-top: -2.25rem;
}
.sectionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 7rem;
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    /*margin-top: 7rem;*/
}

.topContent > .title {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 109.4%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.topContent > .subtitle {
    font-size: 1.75rem;
    line-height: 151.8%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.exploreBtn {
    padding: 0.75rem 3rem;
    background: #F9A61A;
    border-radius: 0.1875rem;
    color: #FFFFFF;

    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;

    margin-bottom: 4.5rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.carouselCommon {
    width: 2.1875rem;
    height: 0.625rem;
    background: #4499E8;
    border-radius: 0.625rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
    cursor: pointer;
    margin-bottom: 3.875rem;
}

.carouselInactive {
    opacity: 0.3;
}

.carouselActive {
    width: 5.0625rem;
}

.rightImage {
    width: 44.1875rem;
    /*height: 35rem;*/
}

.featured {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: #1D1D1D;
}

.featureContainer {

}

.headerParentContainer {
    width: 100%;
    z-index: 102;
    /*margin-top: 2.25rem;*/
    padding-top: 2.25rem;
    position: fixed;
    top: 0;
    transition: all 0.8s;
}

.headerBg {
    background: #FFFFFF;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.06);
}

.headerContainer {
}

.logo {
    width: 10.8125rem;
    height: 2rem;
    margin-right: 4.25rem;
    cursor: pointer !important;
}

.headerItem {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    color: #000000;
    padding-bottom: 2.25rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.headerItemActive {
    padding-bottom: 0.5rem;
    border-bottom: 0.25rem solid #419BEF;;
}

.loginBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #000000;
    padding: 0.325rem 1.875rem;
    border-color: #F8F8F8;
    background: #F8F8F8;
    border-radius: 6.25rem;
    cursor: pointer!important;
    margin-right: 0.625rem;
}

.signUpBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.325rem 1.875rem;
    border-color: #F9A61A;
    background: #F9A61A;
    border-radius: 6.25rem;
    cursor: pointer!important;
}

.topLeftImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 41.375rem;
    height: 36.25rem;
    /*height: ;*/
    z-index: 101;
}

.middleLeftImage {
    position: absolute;
    left: 0;
    top: 94rem;
    z-index: -5;
    width: 6.75rem;
}

.middleRightImage {
    position: absolute;
    right: 0;
    top: -17.65rem;
}

.gradientText {
    background: -webkit-linear-gradient(#4AA8FF, #004789);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 4.5rem;
    line-height: 109.4%;
    margin-bottom: 2.875rem;
}

.featuredClients {
    border-top: 0.0625rem solid #CDCDCD;
    border-bottom: 0.0625rem solid #CDCDCD;
}

.featuredClients > img {
    width: fit-content;
    height: fit-content;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    opacity: 0.5;
}

.button {
    padding: 0.625rem 1.5rem;
    border-radius: 0.1875rem;
    width: fit-content;
    cursor: pointer !important;
}

.body {
    background-color: #00539A;
}

.subText {
    color: #FFFFFF;
}

.button2 {
    background-color: #FFA82D;
    max-width: 14rem;
    min-height: 4.25rem;
    color: white;
    margin-bottom: 6.313rem;
    cursor: pointer!important;
}


.featureTitle {
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3.125rem;
    color: #1D1D1D;
    margin-bottom: 2.5rem;
}

.featureDesc {
    font-size: 1.125rem;
    line-height: 181.8%;
    color: #1D1D1D;
    margin-bottom: 0.9375rem;
}

.feature1 {
    width: 38.75rem;
    height: 18.75rem;
}

.feature2 {
    width: 37.5rem;
    height: 27.5rem;
}

.feature3Container {
    background: #F6F9FC;
}

.feature4 {
    width: 39.875rem;
    height: 32.0625rem;
}

.feature5 {
    width: 36.25rem;
    height: 26.0625rem;
}

.feature6 {
    width: 40.0625rem;
    height: 28.4375rem;
}

.mapCitiesContainer {
    height: 42.75rem;
    min-height: 42.75rem;
;
}

.mapCities {
    width: 65.375rem;
    height: 42.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.bubblesImageContainerBg {
    background: #00539A;
}

.bubblesImageContainer {
    height: 48.75rem;
    min-height: 48.75rem;
    color: #FFFFFF !important;
}

.bubblesImage {
    width: 66.5rem;
    height: 48.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.textWhite {
    color: #FFFFFF !important;
}

.insurance {
    padding: 6.3125rem 0;
    background: #EFF8FF;
    width: 100%;
}

.carousel {
    background-color: #FFFFFF;
    max-width: 39.25rem;
    min-width: 39.25rem;
    min-height: 5.875rem;
    margin-bottom: 3.25rem;
    margin-top: 4.563rem;
}

.footerParent {
    background: #003A6D;
}


.line {
    width: 78rem;
    height: 0;
    background-color: #FFFFFF;
    border: 0.0625rem solid #FFFF;
    margin-bottom: 2.25rem;
    margin-left: 14rem;
}

.copyright {
    color: #FFFFFF;
}

.fbLogo {
    width: 0.9375rem;
    height: 1.875rem;
}

.twLogo {
    width: 2rem;
    height: 1.625rem;
}
@media(max-width:47.9375rem) {
    .imageStyle {
        margin: 0 auto;
    }
}

.iconStyle {
    border: 0.0625rem solid;
    border-radius: 1.875rem;
    width: 3.125rem;
    height: 3.125rem;
    background-color: #FFFFFF;
}

@media (min-width: 1700px) {
  .mapCitiesContainer {
      position: relative;
  }
  .mapCitiesContainer img {
    right: -110px;
  }
  .globalCoverage {
    max-width: 97.5rem !important;
  }
  .middleRightImage {
    top: -14.375rem;
  }
  .middleLeftImage {
    top: 82rem;
    width: 8.75rem;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
    .middleLeftImage {
      top: 84.5rem !important;
    }
  }

  @media (min-width: 1300px) and (max-width: 1399px) {
    .middleLeftImage {
      top: 85rem !important;
    }
  }

@media (min-width: 993px) and (max-width: 1280px) {
    .mapCities1024 {
      width: 578px;
      margin-left: auto;
      margin-right: 0%;
    }
    .middleRightImage {
      top: -20.90rem;
    }
    .mapCitiesContainer {
        overflow: hidden;
    }
    .middleLeftImage {
        top: 102.5rem;
        width: 5rem;
    }
}

@media (max-width: 1023px) { /* 64rem */
    .signUpLoginContainer {
        margin-top: 0 !important;;
    }
    .hideOnMobile {
        display: none;
    }
    .logo {
        width: 6.375rem;
        height: 1.25rem;
    }
    .generalContainer {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .topLeftImage {
        width: auto;
        height: auto;
        z-index: -1;
    }
    .sectionContainer {
        margin-top: 4.1875rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .gradientText {
        font-weight: bold;
        font-size: 2.0625rem;
        line-height: 109.4%;
        text-align: center;
        margin-bottom: 1.875rem;
    }
    .subtitle {
        font-size: 0.875rem !important;
        line-height: 151.8%;
        text-align: center;
        color: #1D1D1D;
    }
    .exploreBtn {
        font-weight: 600;
        font-size: 0.8125rem;
        line-height: 181.8%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 3.4375rem;
    }
    .featuredClients {
        border: none;
    }
    .featuredClients img {
        width: auto;
        margin-top: 0.5625rem;
        margin-bottom: 0.5625rem;
        max-height: 2.5rem;
    }
    .feature1 {
        width: 22.75rem;
        height: 10.75rem;
    }
    .featureTitle {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.625rem;
        color: #1D1D1D;
    }
    .featureDesc {
        font-size: 0.875rem;
        line-height: 181.8%;
        color: #1D1D1D;
    }
    .button {
        font-size: 0.6875rem;
        padding: 0.5rem 0.8125rem;
    }
    .feature2 {
        width: 19.0625rem;
        height: 14.0625rem;
    }
    .middleRightImage {
        width: 5rem;
        height: 5rem;
        top: -5rem
    }
    .mapCities {
        position: static;
        width: 100%;
        height: auto;
        margin-bottom: 3.75rem;
    }
    .feature4 {
        width: 20rem;
        height: 16.125rem;
    }
    .feature5 {
        width: 22.6875rem;
        height: 16.25rem;
        margin-bottom: 3.375rem;
    }
    .feature6 {
        width: 20.375rem;
        height: 14.375rem;
        margin-bottom: 4.25rem;
    }
    .carousel {
        min-width: 100%;
        font-size: 1rem;
    }
    .reqDemoBtn {
        width: 100% !important;
        text-align: center !important;
    }
    .signUpBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
    }
    .loginBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
    }
    .headerParentContainer {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .middleLeftImage {
        top: 98rem;
        width: 3.375rem;
        /*height: 3.375rem;*/
    }
    .mapCitiesContainer {
        height: auto;
        min-height: auto;
    }
    .bubblesImageContainer {
        height: auto;
        min-height: unset;
        padding-top: 5.625rem;
        padding-bottom: 6.8125rem;
    }
    .bloombergLogo {
        max-width: 6.25rem;
        max-height: 1.5625rem;
    }
    .firstImageStyle {
        width: 95%;
    }
}

@media (min-width: 767px) and (max-width: 900px) {
    .fontStyle {
        font-size: 0.675rem;
    }
    .middleLeftImage {
        top: 105rem;
    }
}

@media (min-width: 901px) and (max-width: 992px) {
    .middleLeftImage {
        top: 110rem;
    }
}

@media (min-width: 700px) and (max-width: 760px) {
    .middleLeftImage {
        top: 100rem;
    }
}

@media (min-width: 550px) and (max-width: 601px) {
    .middleLeftImage {
        top: 95.5rem;
    }
}

@media (width: 414px) {
    .middleLeftImage {
        top: 95.5rem;
    }
}

@media (min-width: 400px) and (max-width: 412px) {
    .middleLeftImage {
        top: 100rem;
    }
}

@media (min-width: 320px) and (max-width: 360px) {
    .middleLeftImage {
        top: 96.5rem;
    }
}
