.generalContainer {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
}

.generalContainer1 {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    background-repeat: no-repeat;
    background-position: right top;
    padding-top: 44px;
    padding-bottom: 50px;
    padding-left: 28px;
    background-size: 66%;
}

.generalContainer2 {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-right: 2rem;
    background-size: 60%;
    background-position: right top;
    background-repeat: no-repeat;
    z-index: 999;
}

.generalContainer3 {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.shadowStyle {
    border-color: #D2D2D2;
}

.shadowStyle:hover{
    border-color: #D2D2D2;
    box-shadow: 0px 0px 10px #d8d8d8;
}

.subtitleStyle {
    color: #989898;
}

.featured {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: #1D1D1D;
}

.sectionContainer {
    margin-top: 7rem;
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    /*margin-top: 7rem;*/
}

.topContent > .title {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 109.4%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.topContent > .subtitle {
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 151.8%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.exploreBtn {
    padding: 0.75rem 3rem;
    background: #4AA8FF;
    border-radius: 0.1875rem;
    color: #FFFFFF;

    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;

    margin-bottom: 4.5rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.headerParentContainer {
    width: 100%;
    z-index: 102;
    /*margin-top: 2.25rem;*/
    padding-top: 2.25rem;
    position: sticky;
    top: 0;
    transition: all 0.8s;
}

.headerBg {
    background: #FFFFFF;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.06);
}

.logo {
    width: 10.8125rem;
    height: 2rem;
    margin-right: 4.25rem;
    cursor: pointer !important;
}

.headerItem {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 181.8%;
    color: #000000;
    padding-bottom: 2.25rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.headerItemActive {
    padding-bottom: 0.5rem;
    border-bottom: 0.25rem solid #419BEF;
}

.signUpBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.625rem 1.875rem;
    border-color: #F9A61A;
    background: #F9A61A;
    border-radius: 6.25rem;
    cursor: pointer!important;
}

.loginBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #000000;
    padding: 0.625rem 1.875rem;
    border-color: #FCFCFC;
    background: #FCFCFC;
    border-radius: 6.25rem;
    cursor: pointer!important;
    margin-right: 0.625rem;
}

.gradientText {
    background: -webkit-linear-gradient(#4AA8FF, #004789);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 3.875rem;
    line-height: 109.4%;
    margin-bottom: 2.875rem;
}

.featuredClients {
    border-top: 0.0625rem solid #CDCDCD;
    border-bottom: 0.0625rem solid #CDCDCD;
}

.featuredClients > img {
    width: fit-content;
    height: fit-content;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    opacity: 0.5;
}

.button {
    padding: 0.625rem 1.5rem;
    border-radius: 0.1875rem;
    width: fit-content;
    cursor: pointer !important;
}

.body {
    background-color: #00539A;
}

.subText {
    color: #FFFFFF;
}

.featureTitle {
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3.125rem;
    color: #1D1D1D;
    margin-bottom: 2.5rem;
}

.featureTitle1 {
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #1D1D1D;
    margin-bottom: 1.4rem;
}

.featureTitleWhite {
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3.125rem;
    color: #FFFFFF;
    margin-bottom: 2.5rem;
}

.featureDesc {
    font-size: 1.125rem;
    line-height: 181.8%;
    color: #1D1D1D;
    margin-bottom: 0.9375rem;
}

.featureDescWhite {
    font-size: 1.125rem;
    line-height: 181.8%;
    color: #FFFFFF;
    margin-bottom: 0.9375rem;
}

.feature1 {
    background: #003465
}

.feature2 {
    width: 37.5rem;
    height: 27.5rem;
}

.feature3Container {
    background: #FFFFFF;
}

.feature4 {
    width: 39.875rem;
    height: 32.0625rem;
}

.feature {
    width: 45.75rem;
    height: 20.75rem;
}

.bubblesImageContainerBg {
    background: #00539A;
}

.bubblesImageContainer {
    height: 48.75rem;
    min-height: 48.75rem;
    color: #FFFFFF !important;
}

.bubblesImage {
    width: 66.5rem;
    height: 48.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.feature5 {
    width: 36.25rem;
    height: 26.0625rem;
}

.feature6 {
    width: 40.0625rem;
    height: 28.4375rem;
}

.globeContainer {
    height: 42.75rem;
    min-height: 42.75rem;
}

.mapCitiesContainer {
    height: 32.75rem;
    min-height: 32.75rem;
}

.mapCities {
    width: 45.375rem;
    height: 28.75rem;
    position: absolute;
    right: -71px;
    top: 0;
}

.globe {
    width: 46.75rem;
    height: 46.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.textWhite {
    color: #FFFFFF !important;
}

.insurance {
    padding: 6.3125rem 0;
    background:#EFF8FF;
    width: 100%;
}

.carousel {
    background-color: #FFFFFF;
    max-width: 39.25rem;
    min-width: 39.25rem;
    min-height: 5.875rem;
    margin-bottom: 3.25rem;
    margin-top: 4.563rem;
}

.footerParent {
    background: #003A6D;
}


.line {
    width: 78rem;
    height: 0;
    background-color: #FFFFFF;
    border: 0.0625rem solid #FFFF;
    margin-bottom: 2.25rem;
    margin-left: 14rem;
}

.copyright {
    color: #FFFFFF;
}

.fbLogo {
    width: 0.9375rem;
    height: 1.875rem;
}

.twLogo {
    width: 2rem;
    height: 1.625rem;
}
@media(max-width:47.9375rem) {
    .imageStyle {
        margin: 0 auto;
    }
}

.iconStyle {
    border: 0.0625rem solid;
    border-radius: 1.875rem;
    width: 3.125rem;
    height: 3.125rem;
    background-color: #FFFFFF;
}

@media (max-width: 1023px) { /* 64rem */
    .hideOnMobile {
        display: none;
    }
    .generalContainer3 {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .logo {
        width: 6.375rem;
        height: 1.25rem;
    }
    .generalContainer {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .gradientText {
        font-weight: bold;
        font-size: 2.0625rem;
        line-height: 109.4%;
        text-align: center;
        margin-bottom: 1.875rem;
    }
    .subtitle {
        font-size: 0.875rem !important;
        line-height: 151.8%;
        text-align: center;
        color: #1D1D1D;
    }
    .exploreBtn {
        font-weight: 600;
        font-size: 0.8125rem;
        line-height: 181.8%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 3.4375rem;
    }
    .featuredClients {
        border: none;
    }
    .featuredClients img {
        width: auto;
        margin-top: 0.5625rem;
        margin-bottom: 0.5625rem;
        max-height: 2.5rem;
    }
    .feature {
        width: 48.75rem;
        height: 20.75rem;
    }
    .feature1 {
        width: 22.75rem;
        height: 10.75rem;
    }
    .featureTitle {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.625rem;
        color: #1D1D1D;
    }
    .featureDesc {
        font-size: 0.875rem;
        line-height: 181.8%;
        color:  #1D1D1D;
    }
    .featureTitleWhite {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.625rem;
        color: #1D1D1D;
    }
    .featureDescWhite {
        font-size: 0.875rem;
        line-height: 181.8%;
        color:  #1D1D1D;
    }
    .button {
        font-size: 0.6875rem;
        padding: 0.5rem 0.8125rem;
    }
    .feature2 {
        width: 19.0625rem;
        height: 14.0625rem;
    }
    .mapCities {
        position: static;
        width: 25.875rem;
        height: 10.0625rem;
        margin-bottom: 3.75rem;
    }
    .feature4 {
        width: 20rem;
        height: 16.125rem;
    }
    .feature5 {
        width: 22.6875rem;
        height: 16.25rem;
        margin-bottom: 3.375rem;
    }
    .feature6 {
        width: 20.375rem;
        height: 14.375rem;
        margin-bottom: 4.25rem;
    }
    .carousel {
        min-width: 100%;
        font-size: 1rem;
    }
    .reqDemoBtn {
        width: 100% !important;
        text-align: center !important;
    }
    .signUpBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
    }
    .loginBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
    }
    .headerParentContainer {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .mapCitiesContainer {
        height: auto;
        min-height: auto;
    }
}

@media(max-width:767px) {
    .generalContainer3 {
        padding-left: 0;
        padding-right: 0;
    }
}
