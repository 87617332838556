.indicators {
  box-sizing: content-box;
  border-radius: 0.125rem;
  height: 0.625rem;
  width: 1.25rem;
  min-width: 1.25rem;
  min-height: 0.625rem;
  margin-right: 0.3125rem;
}
.common {
  background: #E7E7E7;
}

.belowAnd30 {
  background: #E65353;
}

.belowAnd70 {
  background: #FFCC17;
}

.belowAnd100 {
  background: #8CE217;
}

@media (max-width: 768px) {
  .indicators {
    width: 40% !important;
    min-width: 40% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .indicators {
    width: 38.5% !important;
    min-width: 38.5% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}

@media (min-width: 401px) and (max-width: 413px) {
  .indicators {
    width: 46.5% !important;
    min-width: 46.5% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}

@media (min-width: 361px) and (max-width: 400px) {
  .indicators {
    width: 42% !important;
    min-width: 42% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}

@media (min-width: 351px) and (max-width: 360px) {
  .indicators {
    width: 40% !important;
    min-width: 40% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}

@media (max-width: 350px) {
  .indicators {
    width: 34% !important;
    min-width: 34% !important;
    height: 4.625px;
    min-height: 4.625px;
  }
}
