.backBtn {
    color: #30BEF5;
    font-size: 1.125rem;
}

.upgradeBg {
    background-color: #F5FAFF;
}

.heading {
    font-size: 2.625rem;
}

.mainHeading {
    font-size: 2.375rem;
}

.switchColor {
    color: #B7B7B7;
}

.upgradeHeading {
    font-size: 1.75rem;
}

.upgradePlan2 {
    width: 84%;
    margin: 1.5rem auto 0 auto;
    padding: 0.625rem 2rem;
}

.valueSize {
    font-size: 3.5rem;
}

.tableBorder {
    color: #D5D5D5;
}

.textColor {
    color: #666666;
}

.upgradeBorder {
    border: 1px solid #DBDBDB;
    border-radius: 0.625rem;
}

.modalBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.625rem 3.175rem;
    border-color:#4AA8FF;
    background:#4AA8FF;
    border-radius: 0.188rem;
    cursor: pointer!important;
    margin: 1rem 0 3.813rem 0;
}

.outlinedBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #575757;
    padding: 0.625rem 0;
    border: 1px solid #C2C2C2;
    border-radius: 0.188rem;
    cursor: pointer!important;
    width: 85%;
    margin: 2.813rem auto;
}

@media (max-width: 1366px) { /* 64rem */
    .centerHeading {
      font-size: 19.6px;
      line-height: 23.39px;
    }
    .subHeading {
      font-size: 0.788rem;
      line-height: 19.6px;
      margin-top: 0.919rem;
    }
    .textfieldLabel {
      font-size: 0.788rem;
      line-height: 19.6px;
    }
    .heading {
      font-size: 1.794rem;
    }
    .upgradeHeading {
      font-size: 1.225rem;
      line-height: 23.39px;
    }
    .modalBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.481rem 2.175rem;
      margin: 1rem auto 1rem auto;
    }
    .outlinedBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.438rem 2.175rem;
      margin: 1rem auto;
    }
    .value {
      font-size: 1.925rem;
    }
    .valueSize {
      font-size: 2.45rem;
    }
    .mainHeading {
        font-size: 1.5rem;
    }
}