.containerParent {
    position: fixed;
    top: 0;
    width: calc(max(100%, 100vw));
    height: calc(max(100%, 100vh));
    z-index: 99999;
}

.header {
    background: #FFFFFF;
    width: 100%;
    /* max-width: 100vw; */
}

.logo {
    width: 125px;
    height: 25px;
    margin: 33px 0;
}

.overlayBg {
    background: #E5E5E5;
    /* background: #000000; */
    /* opacity: 0.3; */
    height: 100%;
    width: 100%;
    /* max-width: 100vw; */
}

.mainContainer {
    /* background: #FFFFFF; */
    background: #E5E5E5;
    border-radius: 5px;
    width: 90vw;
    height: 50vh;
    margin: 10vh 5vw 0 5vw;
    opacity: 1;
}

.warningLogo {
    width: 65px;
    height: 60px;
}

.warningText {
    font-size: 12px;
    line-height: 133%;
    text-align: center;
    color: #888888;
}
