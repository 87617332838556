.generalContainer {
    margin-left: auto;
    margin-right: auto;
}

.generalContainer1 {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.sectionContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    /*margin-top: 7rem;*/
    max-width: '97.5em';
}

.topContent > .title {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 109.4%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.topContent > .subtitle {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 151.8%;
    color: #1D1D1D;
    margin-bottom: 2.8125rem;
}

.exploreBtn {
    padding: 0.75rem 3rem;
    background: #F9A61A;
    border-radius: 0.1875rem;
    color: #FFFFFF;

    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;

    margin-bottom: 4.5rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.carouselCommon {
    width: 2.1875rem;
    height: 0.625rem;
    background: #4499E8;
    border-radius: 0.625rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
    cursor: pointer;
    margin-bottom: 3.875rem;
}

.carouselInactive {
    opacity: 0.3;
}

.carouselActive {
    width: 5.0625rem;
}

.verticalFontSize {
    font-size: 2rem;
}

.rightImage {
    width: 40.1875rem;
    /*height: 35rem;*/
}

.featured {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: #1D1D1D;
}

.featureContainer {

}

.headerParentContainer {
    width: 100%;
    z-index: 102;
    /*margin-top: 2.25rem;*/
    /* padding-top: 2.25rem; */
    position: fixed;
    top: -1px;
    transition: all 0.8s;
    height: 70px;
    display: flex;
}

.headerBg {
    background: #FFFFFF;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.06);
}

.headerContainer {
}

.logo {
    width: 10.8125rem;
    height: 2rem;
    margin-right: 4.25rem;
    cursor: pointer !important;
}

.headerItem {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    color: #000000;
    padding-bottom: 2.25rem;
    text-transform: uppercase;
    cursor: pointer !important;
}

.headerItemActive {
    padding-bottom: 0.5rem;
    border-bottom: 0.25rem solid #419BEF;;
}

.signUpBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.625rem 1.875rem;
    border-color: #F9A61A;
    background: #F9A61A;
    border-radius: 6.25rem;
    cursor: pointer!important;
}

.loginBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #000000;
    padding: 0.625rem 1.875rem;
    border-color: #F8F8F8;
    background: #F8F8F8;
    border-radius: 6.25rem;
    cursor: pointer!important;
    /* margin-right: 0.625rem; */
}

.topLeftImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 41.375rem;
    height: 36.25rem;
    /*height: ;*/
    z-index: 101;
}

.middleLeftImage {
    position: absolute;
    left: 0;
    top: 88rem;
    z-index: -5;
    width: 8.75rem;
}

.middleRightImage {
    position: absolute;
    right: 0;
    top: -14.375rem;
}

.gradientText {
    background-color: #1D1D1D;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 109.4%;
    margin-bottom: 2.875rem;
}

.featuredClients {
    border-top: 0.0625rem solid #CDCDCD;
    border-bottom: 0.0625rem solid #CDCDCD;
}

.featuredClients > img {
    width: fit-content;
    height: fit-content;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    opacity: 0.5;
}

.button {
    padding: 0.625rem 1.5rem;
    border-radius: 0.1875rem;
    width: fit-content;
    cursor: pointer !important;
}

.body {
    background-color: #00539A;
}

.subText {
    color: #FFFFFF;
}

.button2 {
    background-color: #FFA82D;
    max-width: 14rem;
    min-height: 4.25rem;
    color: white;
    margin-bottom: 6.313rem;
    cursor: pointer!important;
}


.featureTitle {
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3.125rem;
    color: #1D1D1D;
    margin-bottom: 2.5rem;
}

.featureDesc {
    font-size: 1.125rem;
    line-height: 181.8%;
    color: #1D1D1D;
    margin-bottom: 0.9375rem;
}

.feature1 {
    width: 38.75rem;
    height: 18.75rem;
}

.feature2 {
    width: 37.5rem;
    height: 27.5rem;
}

.feature3Container {
    background: #F6F9FC;
}

.feature4 {
    width: 39.875rem;
    height: 32.0625rem;
}

.feature5 {
    width: 36.25rem;
    height: 26.0625rem;
}

.feature6 {
    width: 40.0625rem;
    height: 28.4375rem;
}

.mapCitiesContainer {
    min-height: 42.75rem;
;
}

.mapCities {
    width: 65.375rem;
    height: 42.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.bubblesImageContainerBg {
    background: #00539A;
}

.bubblesImageContainer {
    height: 48.75rem;
    min-height: 48.75rem;
    color: #FFFFFF !important;
}

.bubblesImage {
    width: 66.5rem;
    height: 48.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
}

.textWhite {
    color: #FFFFFF !important;
}

.insurance {
    padding: 6.3125rem 0;
    background: #EFF8FF;
    width: 100%;
}

.carousel {
    background-color: #FFFFFF;
    max-width: 39.25rem;
    min-width: 39.25rem;
    min-height: 5.875rem;
    margin-bottom: 3.25rem;
    margin-top: 4.563rem;
}

.footerParent {
    background: #003A6D;
}


.line {
    width: 78rem;
    height: 0;
    background-color: #FFFFFF;
    border: 0.0625rem solid #FFFF;
    margin-bottom: 2.25rem;
    margin-left: 14rem;
}

.copyright {
    color: #FFFFFF;
    font-size: 1rem;
}

.fbLogo {
    width: 0.9375rem;
    height: 1.875rem;
}

.twLogo {
    width: 2rem;
    height: 1.625rem;
}

.verticalTimeline::before {
    /* background: #4499e8 !important; */
    width: 0rem;
    /* left: 48% !important; */
    width: 2px;
    border-left: 3px dotted #4499e8;
}

@media(max-width:47.9375rem) {
    .imageStyle {
        margin: 0 auto;
    }
}

.iconStyle {
    border: 0.0625rem solid;
    border-radius: 1.875rem;
    width: 3.125rem;
    height: 3.125rem;
    background-color: #FFFFFF;
}

@media (max-width: 1024px) { /* 64rem */
    .headerbgImage {
        background-color: #FFFFFF;
    }
    .generalContainer1 {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .dropdownStyle {
        height: 100vh;
        /* background: #fff; */
        right: 0;
        width:40%;
        position: fixed !important;
        top: 69px !important;
        transform: none !important;
        left: auto !important;
    }
    .paperStyle {
        box-shadow: none !important;
        height: 100%;
        border-radius: 0 !important;
    }
    .overlay {
        position: relative;
    }
    .overlay:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        top: 0;
    }
    .dropdownStyle ul li {
        padding: 20px 30px !important;
        font-weight: 600;
        color: #1D1D1D;
    }
    
    .hideOnMobile {
        display: none;
    }
    .centerHeading {
        text-align: left;
        padding-left: 2.75rem;
        font-size: 2.625rem;
        line-height: 50.12px;
    }
    .centerImage {
        text-align: left;
        /* padding-left: 1.1rem; */
    }
    .topImageStyle {
        display: none;
    }
    .logo {
        width: 6.375rem;
        height: 1.25rem;
    }
    .verticalFontSize {
        font-size: 1.75rem;
    }
    .generalContainer {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .topLeftImage {
        width: auto;
        height: auto;
        z-index: -1;
        display: none;
    }
    .sectionContainer {
        padding-top: 4.1875rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        background: url('./personsGroup.png');
        height: 650px;
    }
    .gradientText {
        font-weight: bold;
        font-size: 4.5rem;
        line-height: 109.4%;
        margin-bottom: 1.875rem;
        background-color: #FFFFFF;
        padding-left: 1.1rem;
        max-width: 75%;
    }
    .topContent > .subtitle {
        font-size: 1.125rem !important;
        line-height: 151.8%;
        /* text-align: center; */
        color: #FFFFFF;
        padding-left: 1.1rem;
        max-width: 65%;
    }
    .exploreBtn {
        font-weight: 600;
        font-size: 1rem;
        line-height: 181.8%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 3.4375rem;
        margin-left: 1.1rem;
    }
    .featuredClients {
        border: none;
    }
    .featuredClients img {
        width: auto;
        margin-top: 0.5625rem;
        margin-bottom: 0.5625rem;
        max-height: 2.5rem;
    }
    .feature1 {
        width: 22.75rem;
        height: 10.75rem;
    }
    
    
    .button {
        font-size: 0.6875rem;
        padding: 0.5rem 0.8125rem;
    }
    .feature2 {
        width: 19.0625rem;
        height: 14.0625rem;
    }
    .middleRightImage {
        width: 5rem;
        height: 5rem;
        top: -5rem
    }
    .mapCities {
        position: static;
        width: 25.875rem;
        height: 10.0625rem;
        margin-bottom: 3.75rem;
    }
    .feature4 {
        width: 20rem;
        height: 16.125rem;
    }
    .feature5 {
        width: 22.6875rem;
        height: 16.25rem;
        margin-bottom: 3.375rem;
    }
    .feature6 {
        width: 20.375rem;
        height: 14.375rem;
        margin-bottom: 4.25rem;
    }
    .carousel {
        min-width: 100%;
        font-size: 1rem;
    }
    .reqDemoBtn {
        width: 100% !important;
        text-align: center !important;
    }
    .signUpBtn, .loginBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
        line-height:1.25rem;
        width: 100%;
        border-radius: 3px;
    }
    .loginBtn {
        border: 1px solid #ddd;
    }
    /* .headerParentContainer {
        padding-top: 1rem;
        padding-bottom: 1rem;
    } */
    .middleLeftImage {
        top: 95rem;
        width: 3.375rem;
        /*height: 3.375rem;*/
    }
    .mapCitiesContainer {
        height: auto;
        min-height: auto;
    }
    .bubblesImageContainer {
        height: auto;
        min-height: unset;
        padding-top: 5.625rem;
        padding-bottom: 6.8125rem;
    }
    .bloombergLogo {
        max-width: 6.25rem;
        max-height: 1.5625rem;
    }
}
@media(max-width:1023px) {
    .gradientText {
        font-size:2.625rem;
        max-width: 100%;
    }
    .verticalTimeline::before {
        left: 4.8px !important;
    }
    .featureTitle {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: #1D1D1D;
    }
   
}

@media(max-width:990px) {
    .featureDesc {
        font-size: 0.875rem;
        line-height: 181.8%;
        color: #1D1D1D;
    }
    .dropdownStyle ul li {
        padding: 0 45px;
    }
}

@media(max-width:767px) {
    br{
        display: none;
    }
    .generalContainer1 {
        padding-left: 0;
        padding-right: 0;
    }
    .generalContainer {
        padding-left: 0;
        padding-right: 0;
    }
    /* .centerImage {
        padding-left: 0;
    } */
    .dropdownStyle {
        width: 67%;
    }
    .centerImage img {
        width: 100%;
    }
    .topContent > .subtitle {
        font-size: 0.875rem !important;
        line-height: 151.8%;
        /* text-align: center; */
        color: #FFFFFF;
        padding-left: 1.1rem;
        max-width: 75%;
    }
    .centerHeading {
        text-align: left;
        padding-left: 2.75rem;
        font-size: 1.375rem;
    }
    .orderStyle {
        order: 2;
    }
    .menu {
        width: 20px;
    }
}
@media(max-width:640px) {
  .verticalTimelineHeadind {
      padding: 0.5rem 2.4rem ;
  }
  .verticalTimeline::before {
    left: -0.930rem !important;
  }
  .verticalTimelineYear span{
      margin-left: 1rem !important;
      margin-bottom: 7rem !important;
  }
  .centerHeading {
    line-height: 26.25px;
    padding-left: 2.3rem;
  }
  .mentionsImageStyle img {
    width: 70px;
    height: 70px;
  }
}

@media(max-width:400px) {
    .vertical-timeline-element-icon {
      left: -18px;
  }
  }

