.input {
    width: 23.125rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 158.8%;
    color: #000000;

    border: 0.0625rem solid #CECECE;
    border-radius: 0.3125rem;
    padding: 1.125rem 1.125rem 1.125rem 3rem;
}
.settingsInput {
    width: 23.125rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 158.8%;
    color: #000000;

    border: 0.0625rem solid #CECECE;
    border-radius: 0.3125rem;
    padding: 1.125rem 1.125rem 1.125rem 1rem;
}
.input::placeholder {
    color: #c4c4c4;
    font-weight: 400;
    font-size: 1rem;
}
.input:focus {
    outline: none;
}

.title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 158.8%;
    color: #000000;
    margin-left: 0.375rem;
    margin-bottom: 0.375rem;
}

.hoverErrorIcon {
    width: 1.25rem;
    height: 1.25rem;
}

.inputIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.star {
    color: #ff0000;
}

@media (max-width: 1023px) {
    .input {
        width: 23.125rem !important;
    }
}

@media (max-width: 767px) {
    .input {
        width: 100% !important;
    }
}