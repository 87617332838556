.logoHeader {
    width: 7.8125rem;
    height: 1.4375rem;
    margin-right: 0.8125rem;
}
.headingStyle {
    font-size: 1.625rem;
    line-height: 2.581rem;
}
.messageError {
    background: #FF6964;
    padding: 12px 0;
    color: #FFFFFF;
}

.messageSuccess {
    background: #2ED86E;
    padding: 12px 0;
    color: #FFFFFF;
}

.messageInfo {
    background: #30BEF5;
    padding: 12px 0;
    color: #FFFFFF;
}
