.overlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: unset;
}

.filterContainer {
  background: #FFFFFF;
  border-radius: 0.313rem;
  padding-bottom: 2.125rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  width: 100%;
  max-height: 80vh;
}

.filterContainer4 {
  background: #FFFFFF;
  border-radius: 0.313rem;
  padding-bottom: 2.125rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  width: 100%;
  max-height: 80vh;
}


.filterContainer2 {
  background: #FFFFFF;
  border-radius: 0.313rem;
  padding: 2.8125rem 2.5rem;
  margin: auto;
  max-width: 65%;
  width: 100%;
  overflow-y: auto;
}

.filterContainerCopy2 {
  background: #FFFFFF;
  border-radius: 0.313rem;
  padding: 2.8125rem 2.5rem;
  margin: auto;
  max-width: 65%;
  width: 100%;
  overflow-y: auto;
}

.filterContainer5 {
  background: #FFFFFF;
  border-radius: 0.313rem;
  padding: 2.8125rem 2.5rem;
  margin: auto;
  max-width: 38%;
  width: 100%;
  overflow-y: auto;
}

.filterContainer3 {
  background: #FFFFFF;
  border-radius: 0.313rem;
  display: flex;
  flex-direction: column;
  max-width: 67.8%;
  width: 100%;
  max-height: 67vh;
}

.scrollWidth {
  width: 98%;
  margin-top: 2.5rem;
}

.textColor {
  color: #666666;
}

.headingColor {
  color: #1D1D1D;
  font-size: 1.75rem;
}

.amountColor {
  color: #1D1D1D;
  font-size: 3.188rem;
}

.switchColor {
  color: #B7B7B7;
}

.backBtn {
  color: #30BEF5;
}

.switchBtn {
  color: #4AA8FF;
}

.tableBorder {
  color: #D5D5D5;
}

.upgradeBorder {
  border: 1px solid #DBDBDB;
  border-radius: 0.625rem;
}

.upgradeBg {
  background-color: #F5FAFF;
}

.dialogContent {
  border: 1px solid #E2E2E2;
  border-radius: 0.625rem;
}

.selectedPlan {
  border: 2px solid #30BEF5;
  border-radius: 0.625rem;
}

.heading {
  font-size: 2.625rem;
}

.value {
  font-size: 2.75rem;
}

.valueSize {
  font-size: 3.5rem;
}

.upgradeHeading {
  font-size: 1.75rem;
}

.paymentDialogContent {
  border: 1px solid #E2E2E2;
  border-radius: 0.313rem;
}

.textfieldStyle {
  border: 1px solid #CECECE;
  padding: 1rem 0 1rem 1.125rem;
  width: 100%;
  border-radius: 0.313rem;
}

.textfieldStyle::placeholder {
  color: #666666;
}

.shortfieldStyle {
  border: 1px solid #CECECE;
  padding: 1rem 0 1rem 1.125rem;
  border-radius: 0.313rem;
}

.shortfieldStyle::placeholder {
  color: #666666;
}

.scrollStyle::-webkit-scrollbar {
  width: 0.313rem;
}

  /* Track */
.scrollStyle::-webkit-scrollbar-track {
  background: #DEDEDE;
  border-radius: 31px;
}

  /* Handle */
.scrollStyle::-webkit-scrollbar-thumb {
  background: #ABABAB;
  border-radius: 31px;
}

  /* Handle on hover */
.scrollStyle::-webkit-scrollbar-thumb:hover {
  background: #ABABAB;
}

.modalBtn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 181.8%;
  text-align: center;
  color: #FFFFFF;
  padding: 0.625rem 3.175rem;
  border-color:#4AA8FF;
  background:#4AA8FF;
  border-radius: 0.188rem;
  cursor: pointer!important;
  margin: 1rem 0 3.813rem 0;
}

.roundedModalBtn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 181.8%;
  text-align: center;
  color: #FFFFFF;
  padding: 0.625rem 3.175rem;
  border-color:#4AA8FF;
  background:#4AA8FF;
  border-radius: 0.625rem;
  cursor: pointer!important;
  margin:1rem auto 1rem auto;
}

.successBtn {
  padding: 0.625rem 4.875rem;
}

.paymentDetails {
  width: 100%;
  margin: 2.813rem 0;
}

.upgradePlan {
  width: 50%;
  margin: 2.813rem 0;
  padding: 0.625rem 0;
}

.upgradePlan2 {
  width: 84%;
  margin: 1.5rem auto 0 auto;
  padding: 0.625rem 2rem;
}

.outlinedBtn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 181.8%;
  text-align: center;
  color: #575757;
  padding: 0.625rem 0;
  border: 1px solid #C2C2C2;
  border-radius: 0.188rem;
  cursor: pointer!important;
  width: 50%;
  margin: 2.813rem 0.625rem 2.813rem 0;
}

.roundedOutlinedBtn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 181.8%;
  text-align: center;
  color: #575757;
  padding: 0.625rem 0;
  border: 1px solid #C2C2C2;
  border-radius: 0.625rem;
  cursor: pointer!important;
  margin: 1rem auto 1rem auto;
}

.coinbaseButton button{
  outline: none;
}

.coinbaseButton {
  position: relative;
}

.coinbaseButton a:before {
  content: "Proceed to Payment";
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
}

.coinbaseButton a button {
  color: #4AA8FF;
  width: 360px;
  position: absolute;
  left: 0px;
  height: 20px;
  font-size: 0px;
}

.heigthStyle {
  height: auto;
}

@media(max-width:1919px) {
  .heigthStyle {
    height: 80%;
  }
}

@media(max-width:1536px) {
  .filterContainerCopy2 {
    padding: 2.5125rem 2.5rem 0 2.5rem;
  }
}

@media (max-width: 1366px) { /* 64rem */
    .filterContainerCopy2 {
      padding: 2.5125rem 2.5rem;
    }
    .centerHeading {
      font-size: 19.6px;
      line-height: 23.39px;
    }
    .subHeading {
      font-size: 0.788rem;
      line-height: 19.6px;
      margin-top: 0.919rem;
    }
    .textfieldLabel {
      font-size: 0.788rem;
      line-height: 19.6px;
    }
    .textfieldStyle {
      padding: 0.7rem 0 0.7rem 0.831rem;
    }
    .shortfieldStyle {
      padding: 0.7rem 0 0.7rem 0.831rem;
    }
    .headingColor {
      font-size: 1.225rem;
    }
    .amountColor {
      font-size: 2.231rem;
    }
    .heading {
      font-size: 1.794rem;
    }
    .upgradeHeading {
      font-size: 1.225rem;
      line-height: 23.39px;
    }
    .subPrice {
      font-size: 1.4rem;
    }
    .modalBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.481rem 2.175rem;
      margin: 1rem auto 1rem auto;
    }
    .roundedModalBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.481rem 2.175rem;
    }
    .paymentDetails {
      margin: 1.5rem 0;
    }
    .cardSubField {
      font-size: 0.7rem;
    }
    .value {
      font-size: 1.925rem;
    }
    .valueSize {
      font-size: 2.45rem;
    }
    .upgradePlan {
      padding: 0.438rem 0;
    }
    .outlinedBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.438rem 2.175rem;
      margin: 1rem 0.438rem 1rem 0;
    }
    .roundedOutlinedBtn {
      font-size: 0.7rem;
      line-height: 20.36px;
      padding: 0.481rem 2.175rem;
    }
    .filterContainer3 {
      max-height: 70vh;
    }
    .filterContainer2 {
      padding: 2rem 2.5rem 1rem;
    }
    .scrollWidth {
      margin-top: 0.8rem;
    }
}
