.roundIcon {
    min-width: 2.5rem;
    width: 2.5rem;
    min-height: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0px solid;
}

.paddedShadow {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    padding: 0.75rem;
    background: #FFFFFF;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.08);
    border-radius: 0.9375rem;
}

.scoreNumber {
    position: absolute;
    background: #F9A61A;
    border-radius: 0.1875rem;
    padding: 0.0625rem 0.3125rem;
    bottom: -0.25rem;
    right: -0.75rem;
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 1.0625rem;
}

.roundIconBorder:before {
    width: 2.563rem;
    height: 2.563rem;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;      
    border-radius: 50%;
}

.bordergt70:before {
    border: 0.2rem solid transparent /* #8CE217 */;
}
.bordergt35:before {
    border: 0.2rem solid transparent /* #F9A61A */;
}
.borderlt35:before {
    border: 0.2rem solid transparent /* #ED2D27 */;
}

@media (min-width: 481px) and (max-width: 992px) {
    .scoreNumber {
        padding: 0.0525rem 0.225rem;
        bottom: -0.10rem;
        font-size: 0.625rem;
        line-height: 1rem;
    }
}

@media (max-width: 480px) {
    .scoreNumber {
        padding: 0.0225rem 0.225rem;
        bottom: 0.10rem;
        font-size: 0.575rem;
        line-height: 0.925rem;
    }
}
