.outlinedBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #676767;
    padding: 0.625rem 0;
    border: 1px solid #C2C2C2;
    border-radius: 0.188rem;
    cursor: pointer!important;
    margin: 1rem 1rem 3.813rem 1rem;
  }

.modalBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.625rem 3.175rem;
    border-color:#4AA8FF;
    background:#4AA8FF;
    border-radius: 0.188rem;
    cursor: pointer!important;
    margin: 1rem 1rem 3.813rem 1rem;
}

.tableBorder tr td {
    border-right: solid 1px rgba(229, 231, 235, 1);
}

.tableBorder tr:first-child td {
    padding-top: 30px;
}

.generalContainer {
    max-width: 97.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.basicList {
    height: 7.5rem;
}

.upgradeBg {
    background-color: #F5FAFF;
}

.proList {
    height: 6.5rem;
}

.teamList {
    height: 6.5rem;
}

.badgeStyle {
    color: #ffffff;
    background-color: #4ECA2C;
    border-radius: 0.188rem;
}

.featured {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: #1D1D1D;
}

.headerParentContainer {
    width: 100%;
    z-index: 102;
    /*margin-top: 2.25rem;*/
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: sticky;
    top: 0;
    transition: all 0.8s;
}

.headerBg {
    background: #FFFFFF;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.06);
}

.signUpBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #FFFFFF;
    padding: 0.625rem 1.875rem;
    border-color: #F9A61A;
    background: #F9A61A;
    border-radius: 6.25rem;
    cursor: pointer!important;
}

.loginBtn {
    font-weight: 600;
    font-size: 1rem;
    line-height: 181.8%;
    text-align: center;
    color: #000000;
    padding: 0.625rem 1.875rem;
    border-color: #F8F8F8;
    /*background: #F8F8F8;*/
    border-radius: 6.25rem;
    cursor: pointer!important;
    /* margin-right: 0.625rem; */
}

.featuredClients {
    border-top: 0.0625rem solid #CDCDCD;
    border-bottom: 0.0625rem solid #CDCDCD;
}

.featuredClients > img {
    width: fit-content;
    height: fit-content;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    opacity: 0.5;
}

.button {
    padding: 0.625rem 1.5rem;
    border-radius: 0.1875rem;
    width: fit-content;
    cursor: pointer !important;
}

.body {
    background-color: #00539A;
}

.subText {
    color: #FFFFFF;
}

.featureTitle {
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 3.125rem;
    color: #1D1D1D;
    margin-bottom: 2.5rem;
}

.feature3Container {
    background: #F5FAFF;
}

.textWhite {
    color: #FFFFFF !important;
}

.footerParent {
    background: #003A6D;
}

.line {
    width: 78rem;
    height: 0;
    background-color: #FFFFFF;
    border: 0.0625rem solid #FFFF;
    margin-bottom: 2.25rem;
    margin-left: 14rem;
}

.copyright {
    color: #FFFFFF;
    font-size: 1rem;
}

.logo {
    width: 10.8125rem;
    height: 2rem;
    margin-right: 4.25rem;
    cursor: pointer !important;
}

.fbLogo {
    width: 0.9375rem;
    height: 1.875rem;
}

.twLogo {
    width: 2rem;
    height: 1.625rem;
}

.heading {
    font-size: 2.625rem;
}

.valueSize {
    font-size: 3.5rem;
}

.switchColor {
    color: #B7B7B7;
}

.textColor {
    color: #666666;
}

.divStyle {
    height: 74.5px;
}

@media(min-width:1536px) {
    .basicList {
        height: 5rem;
    }

    .proList {
        height: 4.8rem;
    }
    .teamList {
        height: 3.6rem;
    }
    .valueSize {
        margin-top: 45px;
    }
    .divStyle {
        height: 166.5px;
      }
  }

@media (min-width: 1367px) and (max-width: 1500px) {
  .heading {
    font-size: 2rem;
  }
  .divStyle {
    height: 132.5px;
  }
}

@media(max-width: 1366px) {
    .modalBtn {
        font-size: 0.7rem;
        line-height: 20.36px;
        padding: 0.481rem 2.175rem;
        margin: 1rem;
    }
    .outlinedBtn {
        font-size: 0.7rem;
        line-height: 20.36px;
        padding: 0.438rem 2.175rem;
        margin: 1rem;
    }
    .heading {
        font-size: 1.794rem;
    }
    .valueSize {
        font-size: 2.45rem;
    }
    .subHeading {
        font-size: 0.788rem;
        line-height: 19.6px;
        margin-top: 0.919rem;
    }
}

@media(max-width:47.9375rem) {
    .imageStyle {
        margin: 0 auto;
    }
}

.iconStyle {
    border: 0.0625rem solid;
    border-radius: 1.875rem;
    width: 3.125rem;
    height: 3.125rem;
    background-color: #FFFFFF;
}
.toggle_btn .MuiSwitch-root {
    width: 52px !important;
    height: 25px !important;
    padding:0 !important;
    border-radius: 20px !important;
    margin: 0 15px !important;
}
@media (max-width: 1024px) { /* 64rem */
    .headerbgImage {
        background-color: #FFFFFF;
    }
    .headerParentContainer {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .paperStyle {
        box-shadow: none !important;
        height: 100%;
        border-radius: 0 !important;
    }
    .overlay {
        position: relative;
    }
    .overlay:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        top: 0;
    }
    .dropdownStyle ul li {
        padding: 20px 30px !important;
        font-weight: 600;
        color: #1D1D1D;
    }

    .hideOnMobile {
        display: none;
    }
    .centerHeading {
        text-align: left;
        padding-left: 2.75rem;
        font-size: 2.625rem;
        line-height: 50.12px;
    }
    .centerImage {
        text-align: left;
        /* padding-left: 1.1rem; */
    }
    .topImageStyle {
        display: none;
    }
    .logo {
        width: 6.375rem;
        height: 1.25rem;
    }
    .verticalFontSize {
        font-size: 1.75rem;
    }
    .generalContainer {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .featuredClients {
        border: none;
    }
    .featuredClients img {
        width: auto;
        margin-top: 0.5625rem;
        margin-bottom: 0.5625rem;
        max-height: 2.5rem;
    }
    .button {
        font-size: 0.6875rem;
        padding: 0.5rem 0.8125rem;
    }
    .signUpBtn, .loginBtn {
        padding: 0.4375rem 1.125rem;
        font-size: 0.625rem;
        line-height:1.25rem;
        width: 100%;
        border-radius: 3px;
    }
    .loginBtn {
        border: 1px solid #ddd;
    }
    .bloombergLogo {
        max-width: 6.25rem;
        max-height: 1.5625rem;
    }
}
@media(max-width:1023px) {
    .featureTitle {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: #1D1D1D;
    }

}

@media(max-width:767px) {
    br{
        display: none;
    }
    .generalContainer {
        padding-left: 0;
        padding-right: 0;
    }
    .centerImage img {
        width: 100%;
    }
    .centerHeading {
        text-align: left;
        padding-left: 2.75rem;
        font-size: 1.375rem;
    }
    .orderStyle {
        order: 2;
    }
    .menu {
        width: 20px;
    }
}
@media(max-width:640px) {
  .centerHeading {
    line-height: 26.25px;
    padding-left: 2.3rem;
  }
  .mentionsImageStyle img {
    width: 70px;
    height: 70px;
  }
}
