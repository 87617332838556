.commonHeaderContainer {
    padding: 1.25rem 2.8125rem;
    border-bottom: 0.25rem solid;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.companyHeaderContainer {
    padding: 1.25rem 2.8125rem;
    border-bottom: 0.25rem solid #FCA640;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.headerContainerWithWarning {
    top: 30px !important;
}

.investorHeaderContainer {
    padding: 1.25rem 2.8125rem;
    border-bottom: 0.25rem solid #30BEF5;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.sectorHeaderContainer {
    padding: 1.25rem 2.8125rem;
    border-bottom: 0.25rem solid #4B4BD0;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.locationHeaderContainer {
    padding: 1.25rem 2.8125rem;
    border-bottom: 0.25rem solid #F25F5F;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.sectorHeaderWithoutBorder {
    padding: 1.25rem 2.8125rem;
    border-bottom: 1px solid #E6E6E6;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 76px;
}

.logoHeader {
    width: 7.8125rem;
    height: 1.4375rem;
    margin-right: 0.8125rem;
}

.pageTitle {
    font-size: 1.3125rem;
    line-height: 1.5625rem;
    color: #000000;
}

.headIcon {
    margin: 0 0.9375rem;
    width: 0.8rem;
    height: 0.8rem;
}

.bellIcon {
    margin: 0 0.9375rem;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
}

.notificationIcon {
    cursor: pointer;
    margin: 0 0.9375rem;
    height: 1.43rem;
    width: 1.25rem;
}

.userIcon {
    width: 3.5rem;
    height: 3.5rem;
}

.signedIn {
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: #AFAFAF;
    margin-bottom: 0.125rem;
}

.userName {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #000000;
}

.searchResultLogo {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 7px;
}

.searchDropdown {
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #CECECE;
    border-top: 0;
    border: none;
}

.searchDropdown li {
    color: #000;
}

.searchList {
    background-color: rgb(48, 190, 245);
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
}

.dropdownResultImage {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    /* background-color: #C4C4C4; */
    border: solid 1px #C4C4C4;
    /* #ee4328; */
    overflow: hidden;
}

.warningRibbon {
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    background: #FCA640;
    color: #FFFFFF;
    z-index: 99;
    cursor: pointer;
    position: fixed;
    top: 0;
}

.belliconmainBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.25rem;
    position: static;
    width: 100%;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    justify-content: space-between;
    gap: 20px;
}

.belliconmainBox:hover {
    background: #F3FCFF;
}

.notificationimg {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 6px; */
    position: static;
    /* width: 2.5rem; */
    background: #E1F8FF;
    border-radius: 3.125rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.middlebox {
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    white-space: break-spaces;
}

.middlebox h1 {
    font-weight: 600;
    color: #3A3A3A;
    font-size: 1rem;
}

.middlebox p {
    color: #8D8D8D;
    font-weight: 400;
    font-size: 0.875rem;
}

.left {
    font-weight: 600;
    font-size: 0.75rem;
    /* line-height: 0.875rem; */
    text-align: right;
    color: #8E8E8E;
}

.mainbox {
    width: 28.375rem;
    margin-top: 2rem;
    z-index: 1;
    right: 50px;
    top: 20px;
}

.mainbox ul {
    padding: 5px;
    padding-bottom: 1px;
}

.mainbox ul li {
    padding: 0px;
    margin: 0px;
    margin-bottom: 5px;
    background-color: transparent !important;
}

.mainbox ul li:hover {
    background-color: transparent !important;
}

.boxshadowdown {
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 10%) !important;
    transition: none !important;
    height: 29.125rem;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 0 2px 4px #ccc;
}

.boxshadowdown::-webkit-scrollbar {
    width: 5px;
}

.boxshadowdown::-webkit-scrollbar-track {
    background: #DEDEDE;
    border-radius: 31px;
}

/* Handle */
.boxshadowdown::-webkit-scrollbar-thumb {
    background: #ABABAB;
    border-radius: 31px;
}

/* Handle on hover */
.boxshadowdown::-webkit-scrollbar-thumb:hover {
    background: #ABABAB;
}

.boxshadowdown li {
    transition: none !important;
}