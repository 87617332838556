.firstImage {
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 5px;
}

.fifthImage {
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 5px;
    height: 35px;
}

.iconStyle {
    width: 55px;
}

.iconStyle1 {
    width: 20%;
    border-radius: 50%;
}

.iconStyle2 {
    width: 15%;
    border-radius: 50%;
}

.iconStyle3 {
    width: 12%;
}

.mainBoxShadowStyle {
    /*opacity: 0;*/
    /*transform: translateX(25vw);*/
    box-shadow: 0px 0px 30px #d8d8d8;
    border-radius: 5px;
    width: 80%;
    background-color: #fff;
    padding-bottom: 10px;
}

.innershadowStyle {
    opacity: 1;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 65px;
    width: 90%;
    padding: 5px 20px 0 20px;
    background-color: #fff;
}

.innershadowStyle1 {
    opacity: 1;
}

.fifthImageShadowStyle {
    box-shadow: -10px 24px 64px -30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 160px;
    width: 68%;
    padding: 18px 20px 0 20px;
}

.globalShadowStyle {
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    width: 26%;
    padding: 10px 12px 8px 12px;
    background-color: #fff;
}

.comprehensiveInsightsShadowStyle {
    box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.15);
    border-radius: 55px;
    width: 55%;
    padding: 18px 20px 0 20px;
}

.secondImageShadowStyle {
    box-shadow: 0px 0px 30px #d8d8d8;
    border-radius: 5px;
    height: 160px;
    width: 40%;
    padding: 10px 20px 0 20px;
    background-color: #fff;
}

.circleshadowStyle1 {
    box-shadow: 0px 0px 30px #d8d8d8;
    border-radius: 50%;
    height: 105px;
    width: 105px;
    padding: 10px 20px 0 20px;
    border: solid 5px #5eca2e;
    border-left-color: #fff;
}

.circleshadowStyle2 {
    border-color: #F9A61A !important;
    border-top-color: #fff !important;
}

.circleshadowStyle3 {
    border-radius: 50%;
    height: 105px;
    width: 105px;
    padding: 10px 20px 0 20px;
}

.comprehensiveInsightscircle {
    box-shadow: 0px 0px 30px #d8d8d8;
    border-radius: 50%;
    height: 85px;
    width: 85px;
    padding: 10px 20px 0 20px;
}

.badgeStyle1 {
    padding: 2px 6px;
    color: #fff;
    background-color: #5eca2e;
    font-size: 10px;
    position: absolute;
    bottom: 3px;
    right: -5px;
    border-radius: 2px;
}

.badgeStyle2 {
    background-color: #F9A61A;
}

.dotStyle {
    width: 3px;
    height: 3px;
    background-color: #A9A9A9;
    border-radius: 50%;
}

.divStyle {
    width: 100%;
}

.frameWidth {
    width: 520px;
}

.comprehensiveFrameWidth {
    width : 520px;
    padding-bottom: 10px;
}

.healthBarometerFrame {
    width: 540px;
    padding-bottom: 10px;
}

.DigitalAssetsFrame {
    width: 520px;
    padding-bottom: 10px;
}

.globalCoverageFrame {
    width: 766px;
    margin-left: auto;
    background-size: contain;
    height: 524px;
    background-repeat: no-repeat;
}

.placeholder  {
    width: 50px !important;
    height: 6px;
    border-radius: 3px !important;
}


@media (min-width: 1500px) {
    .comprehensiveInsightscircle {
        padding: 8px 20px 0 20px;
    }
    .worldGrowth {
      width: 645px !important;
      margin-left: 0 !important;
    }
    .healthBarometerFrame {
        width: 580px;
    }
    .frameWidth {
        width: 590px;
    }
    .DigitalAssetsFrame {
        width: 620px;
    }
    .comprehensiveInsightsShadowStyle {
        width: 62%;
    }
    .graphStyle {
        width: 122px;
    }
    .imageStyle {
        width: 45px;
    }
    .barometerGraph {
        width: 100%;
        position: relative;
        top: -10px;
    }
    .fifthImageShadowStyle {
        width:65%;
    }
    .barometerIconStyle {
        width: 55px;
    }
    .iconStyle1 {
        width: 15%;
        height: 15%;
    }
    .lockDivStyle {
      margin-left: 6.125rem;
    }
    .lockIconStyle {
        width: 100%;
    }
    .globalShadowStyle {
        width: 34%;
    }
}

@media (min-width: 1440px) and (max-width: 1536px) {
    .worldGrowth {
        width: 530px !important;
    }
    .frameWidth {
        width: 590px;
    }
    .secondImageShadowStyle {
        height: 167px;
    }
    .firstCard {
        top: 6rem;
    }
    .secondCard {
        top: 2rem;
    }
    .thirdCard{
        top: 23%;
    }
    .fourthCard {
        bottom: 11rem;
    }
    .fifthCard {
        bottom: 9rem;
    }
}

@media (min-width: 993px) and (max-width: 1204px) {
    .badgeStyle1 {
        padding: 1px 5px;
        bottom: -3px;
    }
    .healthBarometerFrame {
        width: 37.5rem;
    }
    .DigitalAssetsFrame {
        width: 508px;
    }
    .fifthImageShadowStyle {
        width: 108%;
    }
    .circleshadowStyle1 {
        height: 88px;
        width: 105px;
        padding: 10px 15px 0 15px;
    }
    .midCard {
        transform: translateY(0px) scaleX(1.5) scaleY(1.5);
    }
}

@media (min-width: 481px) and (max-width: 992px) {
    .mainBoxShadowStyle {
        width: 100%;
    }
    .innershadowStyle {
        padding: 0px 20px 0 20px;
    }
    .handlePadding {
        padding: 0px 10px 0 10px;
    }
    .worldGrowth {
        width: 32.5rem !important;
    }
    .secondImageShadowStyle {
        padding: 10px 9px 0 20px;
        height: 198px;
    }
    .midCard {
        min-width: 235px;
        transform: translateY(0px) scaleX(1.3) scaleY(1.3);
    }
    .comprehensiveFrameWidth {
        width: 25.5rem !important;
    }
    .comprehensiveInsightsShadowStyle {
        width: 70%;
        padding: 12px 12px 0 12px;
        min-height: 78px;
    }
    .comprehensiveInsightscircle {
        height: 75px;
        width: 75px;
        padding: 15px 15px 0 15px;
    }
    .iconStyle2 {
        width: 17%;
        margin-left: 5px;
    }
    .healthBarometerFrame {
        width: 32.5rem;
    }
    .fifthImageShadowStyle {
        height: 160px;
        width: 70%;
        padding: 12px 7px 0 7px;
    }
    .subHeading {
        font-size: 0.70rem;
    }
    .badgeStyle1 {
        padding: 1px 5px;
        font-size: 8.5px;
    }
    .circleshadowStyle1 {
        height: 85px;
        width: 85px;
        padding: 3px 17px 0 17px;
    }
    .iconStyle {
        width: 3.25rem;
    }
}

@media (max-width: 480px) {
  .placeholder  {
      width: 25px !important;
      height: 4px !important;
  }
  .mainBoxShadowStyle {
      width: 100% !important;
  }
  .mainCard {

  }
  .subCard {
    font-size: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .innershadowStyle {
    width: 96%;
    padding: 5px 20px 0 11px;
    height: 50px;
  }
  .firstImage {
    padding-left: 0px;
    margin-left: 10px;
    width: 57.11px;
  }
  .worldGrowth {
    width: 278px !important;
    /* margin-left: 0 !important; */
  }
  .secondImageShadowStyle {
    padding: 10px 9px 0 9px;
  }
  .midCard {
      min-width: 153.15px;
      width: inherit;
      transform: translateY(0px) scaleX(1.2) scaleY(1.2);
  }
  .frameWidth {
      width: 100%;
  }
  .subHeading {
      font-size: 0.50rem;
  }
  .comprehensiveFrameWidth {
      width: 270px !important;
  }
  .comprehensiveInsightsShadowStyle {
    width: 80%;
    padding: 12px 12px 0 12px;
  }
  .comprehensiveInsightscircle {
    height: 45px;
    width: 45px;
    padding: 8px 8px 0 8px;
  }
  .healthBarometerFrame {
      width: 100%;
    }
    .circleshadowStyle1 {
        height: 55px;
        width: 55px;
        padding: 7px 8px 0 8px;
        border: solid 3px #5eca2e;
        border-left-color: #fff !important;
    }
    .badgeStyle1 {
        padding: 1px 4px;
        font-size: 8px;
        bottom: -1px;
        right: -2px;
    }
    .iconStyle {
        width: 39px;
     }
    .fifthImageShadowStyle {
        height: 124px;
        width: 70%;
        padding: 12px 7px 0 7px;
    }
    .DigitalAssetsFrame {
        width: 278px;
    }
    .circleshadowStyle3 {
        height: auto;
        width: 40px;
        padding: 10px 0px 0 0px;
    }
    .shadowNone {
        box-shadow: none;
    }
    .cardMargin {
        margin-left: 14px;
    }
}

@media (min-width: 300px) and (max-width: 480px) {
    .comprehensiveFrameWidth {
        width: 290px !important;
    }
    .worldGrowth {
      width: 298px !important;
    }
    .cardTextSize {
        font-size: 0.40rem !important;
    }
    .cardText {
        font-size: 0.65rem !important;
    }
}

@media (min-width: 351px) and (max-width: 380px) {
    .worldGrowth {
      width: 300px !important;
    }
    .cardTextSize {
        font-size: 0.40rem !important;
    }
    .cardText {
        font-size: 0.65rem !important;
    }
}

@media (min-width: 300px) and (max-width: 350px) {
    .worldGrowth {
      width: 282px !important;
    }
    .cardTextSize {
        font-size: 0.40rem !important;
    }
    .cardText {
        font-size: 0.65rem !important;
    }
}

@media (min-width: 1500px) {
  .secondImageShadowStyle {
      height: 180px;
    }
}
