.buttonBg {
  background: #33B1FF;
}

.successBg {
  background: #4ECA2C;
}

.container {
  border-radius: 0.3125rem;
  padding: 1.125rem 0;
  cursor: pointer !important;
}

.successImg {
  width: 1.5625rem;
  height: 1rem;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 158.8%;
  text-align: center;
  color: #FFFFFF;

}
